<script>
    import Advertising from '@/services/Advertising'
    import validationMessages from '@/components/validations'
    import { required } from "vuelidate/lib/validators";
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        components:{
            validationMessages,   ckeditor: CKEditor.component
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                image_removed: false,
                editor: ClassicEditor,
                editorData: '',
                title:'',
                image:'',
                sponsor: {
                    name: '',
                    logo: '',
                    url: '',
                    description: '',
                    attributes: {
                        
                    },
                    file: null
                },
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        validations: {
            sponsor:{
                name: {
                    required,
                }
            },
        },
        methods: {
            refreshSponsorData() { 
                this.$emit('refreshScreen') //event from parent
            },
            addSponsor(){
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {

                    let formData = new FormData();
                    formData.append('name', this.sponsor.name);
                    formData.append('file', this.file);
                    formData.append('url', this.sponsor.url);
                    formData.append('description', this.sponsor.description);
                    formData.append('csrf_token', this.csrf_token);

                    if(this.image_removed && !this.file.length){
                        formData.append('logo', this.sponsor.logo)
                    }

                    Advertising.addSponsor(formData)
                        .then((response) => {
                            const res = response.data.data ? response.data.data : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res){
                                this.successmsg('Sponsor Created!');
                                this.refreshSponsorData();
                                this.closeModal();
                            }else{
                                this.failedmsg('Failed to create new sponsor!')
                            }
                        })
                        .catch(error => {
                            const resData = error.response.data.error ? error.response.data.error : 'Failed';
                            const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                            this.failedmsg(errorMsg)
                        })
                    this.tryingToSubmit = false;
                }
            },
            closeModal() {
                this.showModal = false;
            },

            selectFile(event){
                const file = event.target.files[0]
                this.file = file
                //this.sponsor.logo = URL.createObjectURL(file)
                this.image = URL.createObjectURL(file)
            },

            removeImage(){
                this.logo_e = ''
                this.file_e = ''
                this.image_e = ''
                this.image = ''
                this.$refs['file-input'].reset()
                this.image_removed = true
            },

            resetProps(){
                this.submitted = false
                this.image = '',
                this.sponsor.name = ''
                this.sponsor.logo = ''
                this.sponsor.url = ''
                this.sponsor.description = ''
            }
        }
    };
</script>

<template>
    <b-modal id="add_business_news" size="lg" v-model="showModal" @hidden="resetProps" title="Add Business News" title-class="font-18">
    <form @submit.prevent="addSponsor">
        <div class="row">
          <div class="col-12">
            <b-form-group label="Title" label-for="content" class="mb-3">
               <b-form-input v-model="title"></b-form-input>
               <validationMessages v-if="submitted" :fieldName="'Sponsor Name'" :validationName="$v.sponsor.name"></validationMessages>
            </b-form-group>

            <b-form-group label-for="image" class="mb-3 text-center image-area">
               <img :src="image != '' ? image : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail me-2" >
               <b-form-file @change="selectFile" ref="file-input" type="file" class="mt-3" plain accept="image/jpeg, image/png" ></b-form-file>
               <a v-if="image" class="remove-image" href="javascript:void(0);" @click="removeImage" style="display: inline;">&#215;</a>
            </b-form-group>

            <b-form-group label="Content" label-for="content" class="mb-3">
                <template>
                    <ckeditor :editor="editor" v-model="editorData"></ckeditor>
                </template>
                <validationMessages v-if="submitted" :fieldName="'Sponsor Name'" :validationName="$v.sponsor.name"></validationMessages>
            </b-form-group>
          </div>
        </div>

        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addSponsor" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit</b-button>
        </template>

    </b-modal>
 
</template>